









import Vue from "vue";
import Component from "vue-class-component";
@Component({
  name: "ScrollToTop",
})
export default class ScrollToTop extends Vue{
}
