

































































































































































































































import Vue from "vue";
import Project from './Project.vue';
import Component from "vue-class-component";
import SubscribeBox from './SubscribeBox.vue';
import ScrollToTop from './ScrollToTop.vue';
@Component({
  name: "Startups",
  components: {
    Project,
    SubscribeBox,
    ScrollToTop,
  }
})
export default class Startups extends Vue{
}
