





























export interface ILink {
  name?: string;
  href?: string;
};

import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component({
  name: "Project",
})
export default class Project extends Vue{
  @Prop({ type: String, required: false, default: undefined }) headerLink?: ILink;
  @Prop({ type: Array, required: false, default: undefined }) tags?: Array<string>;
  @Prop({ type: Array, required: false, default: undefined }) descriptions?: Array<string>;
  @Prop({ type: Array, required: false, default: undefined }) points?: Array<string>;
  @Prop({ type: Array, required: false, default: undefined }) links?: Array<ILink>;


}
