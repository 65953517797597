var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","offset-md":"2"}},[_c('h3',{staticClass:"display-4 text-center my-16 pb-16 font-weight-bold"},[_vm._v("Startups")]),_c('SubscribeBox',{staticClass:"my-16"}),_c('Project',{staticClass:"my-16",attrs:{"headerLink":{
          name: '🏄 Habitus - Gamified Emotions, Habit Tracker and Journal',
          href: 'https://habitus.today/'
        },"tags":[
          'TypeScript 3.8',
          'Koa.js 2.13.1',
          'Vue.js 2.6',
          'Vuetify 2.2',
          'Progressive Web App' ],"descriptions":[
          'State-of-the-art Tracker for emotions, habits and thoughts.',
          'Open source.',
          'Anonymous.' ],"points":[
          "<h3>Track emotions,</h3>",
          "<h3>Track thoughts (journal),</h3>",
          "<h3>Track habits (good / bad),</h3>",
          "<h3>Sound notifications,</h3>",
          "<h3>Performance graphs,</h3>",
          "<h3>Anonymous,</h3>",
          "<h3>Storing data only in your browser.</h3>" ],"links":[
          {
            name: 'Live App',
            href: 'https://habitus.today/',
          },
          {
            name: 'Twitter',
            href: 'https://twitter.com/HabitusToday',
          },
          {
            name: 'Medium',
            href: 'https://medium.com/@habitus.today',
          },
          {
            name: 'GitHub - Frontend',
            href: 'https://github.com/AndromedaTechnology/habitus',
          },
          {
            name: 'GitHub - API',
            href: 'https://github.com/AndromedaTechnology/habitus-api',
          } ]}}),_c('Project',{staticClass:"my-16",attrs:{"headerLink":{
          name: '👨🏻‍🏫 Onetius - Slick and Powerful E-Learning Platform',
          href: 'https://onetius.com/'
        },"tags":[
          'Leadership',
          'Product Development',
          'API Development - Laravel',
          'Frontend Development - backbone.js' ],"descriptions":[
          "Learn from others or start your own Courses with your friends or colleagues.",
          "Create Teams to host closed-down Courses for your Company, Family or Friends.",
          "<strong>4900+ Teachers and Students.</strong>" ],"points":[
          "<h3>Open - Anyone can start or follow a course.</h3>",
          "<h3>Unbounded lessons - text, audio, video, photos...</h3>",
          "<h3>Test your knowledge - MicroExams after each lesson.</h3>",
          "<h3>Compare your performance with others - PerformanceGraph.</h3>",
          "<h3>Community discussions.</h3>",
          "<h3>Collaborations - People Nearby.</h3>",
          "<h3>Teams - Public, Private Courses.</h3>" ],"links":[
          {
            name: 'Pitch [VIDEO]',
            href: 'https://www.youtube.com/watch?v=tEkkxMh8JxE',
          },
          {
            name: 'YouTube',
            href: 'https://www.youtube.com/user/Onetius',
          },
          {
            name: 'Facebook',
            href: 'https://facebook.com/Onetius',
          },
          {
            name: 'Press 1',
            href: 'https://www.mojarijeka.hr/onetius-znanje-dostupno-svima-kroz-novu-mreznu-platformu/',
          },
          {
            name: 'Press 2',
            href: 'https://www.netokracija.com/onetius-edukativna-platforma-mario-novak-113910',
          } ]}}),_c('Project',{staticClass:"my-16",attrs:{"headerLink":{
          name: '💪 Iron Heart Festival - Sports Competition and Platform',
          href: 'https://ironheartfestival.com/'
        },"tags":[
          'Leadership',
          'Marketing',
          'Product Development',
          'API Development - Laravel' ],"descriptions":[
          "Sports Competition - Athletes compete for the title of <strong>'The most athletic individual on the Planet'</strong>.",
          "Online Platform - educational videos, training logs of athletes.",
          "<strong>7+ million views on YouTube.</strong>",
          "<strong>20k+ subscribers on YouTube.</strong>" ],"points":[
          "<h3>Perfect mix of disciplines.</h3>",
          "<h3>Benchmark your speed, strength, agility.</h3>",
          "<h3>Compete with others once a year - at IHF Finals in Croatia.</h3>",
          "<h3>Track your progress online - Arena - Digital playground.</h3>",
          "<h3>Academy - Workshops and Lectures - Online and Offline in Croatia.</h3>" ],"links":[
          {
            name: 'Booklet [Slides]',
            href: 'https://docs.google.com/presentation/d/1mtKZiaNoi20_M2644vOoVItaEpQhodYhrDc5C_AVmAI/edit?usp=sharing',
          },
          {
            name: 'Aftermovie',
            href: 'https://www.youtube.com/watch?v=dELRiTYFX70',
          },
          {
            name: 'YouTube',
            href: 'https://www.youtube.com/user/heartrequired/videos?view=0&sort=p&flow=grid',
          },
          {
            name: 'Facebook',
            href: 'https://facebook.com/ironheartfestival',
          },
          {
            name: 'Press',
            href: 'https://www.jutarnji.hr/Promo/najbrze-rastuci-sportski-festival-u-europi-pocinje-za-11-dana-u-hrvatskoj/6484561/',
          } ]}}),_c('h3',{staticClass:"display-4 text-center my-16 py-16 font-weight-bold"},[_vm._v("Open source")]),_c('SubscribeBox',{staticClass:"my-16"}),_c('Project',{staticClass:"my-16",attrs:{"headerLink":{
          name: '🚀 FireStarter API - Progressive Startup API Boilerplate',
          href: 'https://github.com/AndromedaTechnology/firestarter-api'
        },"tags":[
          'TypeScript 3.8',
          'Koa.js 2.13.1',
          'MongoDB',
          'Mongoose',
          'Jest',
          'Docker' ],"descriptions":[
          "Easy to extend, Progressive and Scalable API boilerplate to power your startup." ],"points":[
          "<h3>Scalable architecture</h3>",
          "<h3>Easy to extend</h3>",
          "<h3>Bleeding-edge tools</h3>" ],"links":[
          {
            name: 'GitHub',
            href: 'https://github.com/AndromedaTechnology/firestarter-api',
          } ]}}),_c('Project',{staticClass:"my-16",attrs:{"headerLink":{
          name: '🔭 Ethereum Observer',
          href: 'https://github.com/AndromedaTechnology/ethereum-observer-api'
        },"tags":[
          'Blockchain',
          'Ethereum',
          'Hardhat',
          'Solidity',
          'TypeScript 3.8',
          'Koa.js 2.13.1',
          'MongoDB',
          'Mongoose',
          'Jest',
          'Docker' ],"descriptions":[
          "Simple block and transaction tracker for Ethereum network. " ],"points":[
          "<h3>Tracking block and transaction creation</h3>",
          "<h3>Storing daily summary to Solidity smart contract</h3>" ],"links":[
          {
            name: 'GitHub - API',
            href: 'https://github.com/AndromedaTechnology/ethereum-observer-api',
          },
          {
            name: 'GitHub - Contract - Hardhat',
            href: 'https://github.com/AndromedaTechnology/ethereum-observer-contract-hardhat',
          } ]}}),_c('ScrollToTop',{staticClass:"py-16"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }