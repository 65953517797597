












import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from "vue-property-decorator";
@Component({
  name: "Logo",
  components: {
  }
})
export default class Logo extends Vue {
  @Prop({ type: String, required: false, default: undefined }) name?: string;
  @Prop({ type: String, required: false, default: undefined }) src?: string;
}
