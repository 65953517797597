import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Logos from "@/components/Logos.vue";
import Startups from "../components/Startups.vue";
import HelloWorld from "@/components/HelloWorld.vue";

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: HelloWorld,
    },
    {
      path: "/startups",
      name: "startups",
      component: Startups,
    },
    {
      path: "/logos",
      name: "logos",
      component: Logos,
    },
  ],
});

export default router;
