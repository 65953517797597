













































































import Vue from 'vue';
import SkillLogo from './SkillLogo.vue';
import Component from 'vue-class-component';
@Component({
  name: "HelloWorld",
  components: {
   SkillLogo,
  }
})
export default class HelloWorld extends Vue{
}
