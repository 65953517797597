









































import Vue from "vue";
import Component from "vue-class-component";
@Component({
  name: "SubscribeBox",
})
export default class SubscribeBox extends Vue {
  email = "";
  emailRules = [
    (v: any) => {
      return this.isEmailValid(v) || "E-mail must be valid";
    },
  ];
  isEmailValid(v: any): boolean {
    return !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v);
  }
  get isSubmitDisabled(): boolean {
    return !this.isEmailValid(this.email) || !this.email;
  }
}
