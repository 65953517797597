































































import { Component, Vue } from "vue-property-decorator";
import SWUpdate from "./components/general/SWUpdate.vue";
@Component({
  name: "App",
  components: {
    SWUpdate,
  },
})
export default class App extends Vue {
  //
}
